import { State } from 'app/shared/models';
import { CountryRegionData } from 'react-country-region-selector';

const COUNTRY_VALUE_INDEX = 1;
const RAW_STATE_INDEX = 2;

export const COUNTRY_OPTIONS = CountryRegionData.default.map(
  ([countryName, countrySlug]) => ({
    text: countryName,
    value: countrySlug,
  }),
);
export const getStateOptions = (countryValue: string) => {
  const states = CountryRegionData.default.reduce((acc: State[], curr) => {
    if (curr[COUNTRY_VALUE_INDEX] === countryValue) {
      const rawStates = curr[RAW_STATE_INDEX];
      const stateMap = rawStates.split('|').map((statePair) => {
        const [stateName, stateShortCode] = statePair.split('~');
        return { stateName, stateShortCode };
      });
      acc.push(...stateMap);
    }
    return acc;
  }, []);
  return states.map((i) => ({
    text: i.stateName,
    value: i.stateShortCode,
  }));
};
