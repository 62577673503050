import { CyberEventIndicators } from 'app/modules/fincenSarNew/models';
import isIP from 'validator/lib/isIP';
import isURL from 'validator/lib/isURL';
import isEmail from 'validator/lib/isEmail';
import isPort from 'validator/lib/isPort';
import { Filter } from 'app/modules/filters/models';
import { SAR_FILING_FILTER_KEYS } from 'app/modules/fincenSarNew/filters';
import { FILTER_OPERATOR } from 'app/modules/filters/constants';
import { NewSarFiltersPayload } from 'app/modules/fincenSarNew/requests';
import { FE_BE_STATUS_MAPPING } from 'app/modules/fincenSarNew/status';
import { SarsExportFiltersPayload } from 'app/modules/fileExportsOld/models';
import { FINCEN_SAR_REPORT_TYPE } from 'app/modules/fincenSar/constants/sarFilters';

// Copied from patronus https://github.com/u21/patronus/blob/a7fe002600e0166a48a1c9b3d3bb5149d2a6a09e/server/sars/sar_filing.py#L33
const MAC_ADDRESS_REGEX = /^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F]){2}$/;

export const error44EventValue = (
  value: string | undefined,
  eventIndicator: CyberEventIndicators | undefined | '',
) => {
  if (eventIndicator && !value) {
    return 'Required';
  }
  if (
    ['Command and Control IP Address', 'Suspicious IP Address'].includes(
      eventIndicator || '',
    ) &&
    value
  ) {
    return error43IpAddress(value);
  }
  if (
    ['Command and Control URL/Domain', 'Suspicious URL/Domain'].includes(
      eventIndicator || '',
    ) &&
    value &&
    !isURL(value)
  ) {
    return 'Input valid URL';
  }
  if (
    eventIndicator === 'Media Access Control (MAC) Address' &&
    value &&
    !MAC_ADDRESS_REGEX.test(value)
  ) {
    return 'Input valid MAC address (colon ":" separator only)';
  }
  if (eventIndicator === 'Port' && value && !isPort(value)) {
    return 'Input valid Port number';
  }
  if (
    eventIndicator === 'Suspicious E-mail Address' &&
    value &&
    !isEmail(value)
  ) {
    return 'Input valid email address';
  }
  if (eventIndicator && value && value?.length > 4000) {
    return 'Max 4000 characters allowed';
  }
  return undefined;
};

export const error43IpAddress = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }
  if (!isIP(value)) {
    return 'Input valid IP address (v4 or v6)';
  }
  // shorthand ipv6 is not allowed
  if (value.includes(':') && value.split(':').some((i) => i.length !== 4)) {
    return 'IPv6 must be in the format cccc:cccc:cccc:cccc:cccc:cccc:cccc:cccc';
  }
  return undefined;
};

export const errorPhoneNumber = (value: string | undefined) => {
  if (!value) {
    return undefined;
  }
  if (!/^\d*$/.test(value)) {
    return 'Only numbers allowed';
  }
  if (!/^\d{0,16}$/.test(value)) {
    return 'Max 16 digit number';
  }
  const digitsInValue = new Set(value.split(''));
  if (digitsInValue.size === 1) {
    return 'All digits cannot be same';
  }
  return undefined;
};

const filterNumbers = (array: (number | string)[]): number[] => {
  return array.filter((i) => typeof i === 'number') as number[];
};

const filterStrings = (array: (number | string)[]): string[] => {
  return array.filter((i) => typeof i === 'string') as string[];
};

export const createFilingFilters = (
  filters: Filter[],
): NewSarFiltersPayload => {
  return filters.reduce<NewSarFiltersPayload>((acc, i) => {
    const { key, operator, value } = i;
    switch (key) {
      case SAR_FILING_FILTER_KEYS.BSA_CASE_ID: {
        if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
          acc.search_id = value;
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.CREATED_AT: {
        if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
          acc.created_at_start = new Date(value).toISOString();
        } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
          acc.created_at_end = new Date(value).toISOString();
        } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
          acc.created_at_start = new Date(value[0]).toISOString();
          acc.created_at_end = new Date(value[1]).toISOString();
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.CREATED_BY: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.agent_ids = filterNumbers(value);
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.ENTITY: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.entity_external_ids = filterStrings(value);
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.FILED_AT: {
        if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
          acc.filed_at_start = new Date(value).toISOString();
        } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
          acc.filed_at_end = new Date(value).toISOString();
        } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
          acc.filed_at_start = new Date(value[0]).toISOString();
          acc.filed_at_end = new Date(value[1]).toISOString();
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.SUBJECT_COUNTRY: {
        if (operator === FILTER_OPERATOR.IS) {
          acc.country = String(value);
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.SUBJECT_STATE: {
        if (operator === FILTER_OPERATOR.IS) {
          acc.state = String(value);
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.SUBJECT_CITY: {
        if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
          acc.city = value;
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.LAST_UPDATED_AT: {
        if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
          acc.updated_at_start = new Date(value).toISOString();
        } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
          acc.updated_at_end = new Date(value).toISOString();
        } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
          acc.updated_at_start = new Date(value[0]).toISOString();
          acc.updated_at_end = new Date(value[1]).toISOString();
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.QUEUE: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.queue_ids = filterNumbers(value);
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.REVIEWER: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.reviewer_ids = filterNumbers(value);
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.STATUS: {
        if (operator === FILTER_OPERATOR.IS_ONE_OF) {
          acc.statuses = value.flatMap((j) => FE_BE_STATUS_MAPPING[j]);
        }
        break;
      }

      case SAR_FILING_FILTER_KEYS.SUBMITTED: {
        if (operator === FILTER_OPERATOR.IS_TRUE) {
          acc.submitted = true;
        } else if (operator === FILTER_OPERATOR.IS_FALSE) {
          acc.submitted = false;
        }
        break;
      }

      default:
        break;
    }
    return acc;
  }, {});
};

export const createFilingExportFilters = (
  filters: Filter[],
): SarsExportFiltersPayload => {
  return filters.reduce<SarsExportFiltersPayload>(
    (acc, i) => {
      const { key, operator, value } = i;
      switch (key) {
        case SAR_FILING_FILTER_KEYS.CREATED_AT: {
          if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
            acc.created_at_start = new Date(value).toISOString();
          } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
            acc.created_at_end = new Date(value).toISOString();
          } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
            acc.created_at_start = new Date(value[0]).toISOString();
            acc.created_at_end = new Date(value[1]).toISOString();
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.CREATED_BY: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.agent_ids = filterNumbers(value);
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.FILED_AT: {
          if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
            acc.filed_at_start = new Date(value).toISOString();
          } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
            acc.filed_at_end = new Date(value).toISOString();
          } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
            acc.filed_at_start = new Date(value[0]).toISOString();
            acc.filed_at_end = new Date(value[1]).toISOString();
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.SUBJECT_COUNTRY: {
          if (operator === FILTER_OPERATOR.IS) {
            acc.country = String(value);
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.SUBJECT_STATE: {
          if (operator === FILTER_OPERATOR.IS) {
            acc.state = String(value);
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.SUBJECT_CITY: {
          if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
            acc.city = value;
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.LAST_UPDATED_AT: {
          if (operator === FILTER_OPERATOR.IS_AFTER_DATE) {
            acc.updated_at_start = new Date(value).toISOString();
          } else if (operator === FILTER_OPERATOR.IS_BEFORE_DATE) {
            acc.updated_at_end = new Date(value).toISOString();
          } else if (operator === FILTER_OPERATOR.IS_BETWEEN_DATE) {
            acc.updated_at_start = new Date(value[0]).toISOString();
            acc.updated_at_end = new Date(value[1]).toISOString();
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.STATUS: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.statuses = value.flatMap((j) => FE_BE_STATUS_MAPPING[j]);
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.SUBMITTED: {
          if (operator === FILTER_OPERATOR.IS_TRUE) {
            acc.submitted = true;
          } else if (operator === FILTER_OPERATOR.IS_FALSE) {
            acc.submitted = false;
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.QUEUE: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.queue_ids = filterNumbers(value);
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.REVIEWER: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.reviewer_ids = filterNumbers(value);
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.BSA_CASE_ID: {
          if (operator === FILTER_OPERATOR.CONTAINS_TEXT) {
            acc.search_id = value;
          }
          break;
        }

        case SAR_FILING_FILTER_KEYS.ENTITY: {
          if (operator === FILTER_OPERATOR.IS_ONE_OF) {
            acc.entity_ids = value;
          }
          break;
        }

        default:
          break;
      }
      return acc;
    },
    {
      agent_ids: [],
      statuses: [],
      created_at_start: '',
      created_at_end: '',
      updated_at_start: '',
      updated_at_end: '',
      filed_at_start: '',
      filed_at_end: '',
      report_type: FINCEN_SAR_REPORT_TYPE,
      reviewer_ids: [],
      search_id: '',
      entity_ids: [],
      queue_ids: [],
    },
  );
};

export const sarFormFieldTextValueTransform = (value?: string) => {
  return value?.trim().replace(/\s/g, ' ');
};
