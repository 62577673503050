import randomColor from 'randomcolor';
import { SemanticICONS } from 'semantic-ui-react';
import convert from 'color-convert';

// Models
import {
  PatronusErrorResponseBody,
  PatronusValidationErrorBody,
} from 'app/shared/models';

// Returns if the color is a suitable background color for white text
// Adapted from here: https://stackoverflow.com/a/3943023/1601268
export const isSuitableBackgroundColor = (colorHex: string): boolean => {
  const [red, green, blue] = convert.hex.rgb(colorHex);
  return red * 0.299 + green * 0.587 + blue * 0.114 < 186;
};

export const generateLabelBackgroundColor = (seed: string): string => {
  let count = 0;
  let currentSeed = seed;
  let currentColor = '#FFF';

  while (!isSuitableBackgroundColor(currentColor) && count < 5) {
    currentColor = randomColor({
      luminosity: 'dark',
      format: 'hex',
      seed: currentSeed,
    });
    currentSeed += '.';
    count += 1;
  }

  return currentColor;
};

export const renderAuditTrailIconStyle = (actionType) => {
  let iconColor = '';
  let trailTitleString = '';
  let iconName: SemanticICONS = 'suitcase';
  switch (actionType) {
    // PENDING REFRESH
    case 'CASE_OPEN':
      iconColor = 'green';
      iconName = 'folder open outline';
      trailTitleString = 'Case Opened';
      break;
    case 'CASE_CLOSED':
      iconColor = 'red';
      iconName = 'delete';
      trailTitleString = 'Case Closed';
      break;
    case 'CASE_ACTION_TRIGGERED':
      iconName = 'external square alternate';
      trailTitleString = 'Action Triggered';
      break;
    case 'ALERT_OPENED':
      iconColor = 'green';
      iconName = 'check';
      trailTitleString = 'Alert Opened';
      break;
    case 'ALERT_EDIT':
      iconColor = '#4682B4'; // SteelBlue
      iconName = 'pencil';
      trailTitleString = 'Alert Edited';
      break;
    case 'ALERT_CLOSED':
      iconColor = 'red';
      iconName = 'delete';
      trailTitleString = 'Alert Closed';
      break;
    case 'ALERT_FALSE_POSITIVE':
      iconColor = 'red';
      iconName = 'thumbs down outline';
      trailTitleString = 'Marked Alert as False Positive';
      break;
    case 'ALERT_TRUE_POSITIVE':
      iconColor = 'green';
      iconName = 'spy';
      trailTitleString = 'Marked Alert as True Positive';
      break;
    case 'ALERT_ESCALATE':
      iconColor = '#4682B4'; // SteelBlue
      iconName = 'angle double up';
      trailTitleString = 'Alert Reassigned';
      break;
    case 'ALERT_BLOCK':
      iconColor = '#9370DB'; // MediumPurple
      iconName = 'user delete';
      trailTitleString = 'Alert Blocked';
      break;
    case 'ALERT_REOPEN':
      iconColor = '#008080'; // Teal
      iconName = 'envelope open outline';
      trailTitleString = 'Alert Reopened';
      break;
    case 'ALERT_ENTITIES_ACTION':
      iconColor = 'teal';
      iconName = 'user';
      trailTitleString = 'Alert Entities Action';
      break;
    case 'ALERT_TXNS_ACTION':
      iconColor = '#20B2AA'; // LightSeaGreen
      iconName = 'dollar';
      trailTitleString = 'Alert Transaction Action';
      break;
    case 'ALERT_TXN_INSTRUMENTS_ACTION':
      iconColor = '#4682B4'; // SteelBlue
      iconName = 'book';
      trailTitleString = 'Alert Instrument Action';
      break;
    case 'ALERT_ACTION_TRIGGERED':
      iconName = 'external square alternate';
      trailTitleString = 'Action Triggered';
      break;
    case 'ALERT_INVESTIGATION_VALIDATION_SUCCESS':
      iconColor = 'orange';
      iconName = 'clipboard list';
      trailTitleString = 'Investigation Validated';
      break;
    case 'ALERT_API_OPENED':
      iconColor = 'green';
      iconName = 'check';
      trailTitleString = 'Alert Opened by API';
      break;
    case 'ALERT_API_UPDATED':
      iconColor = '#4682B4'; // SteelBlue
      iconName = 'upload';
      trailTitleString = 'Alert Updated by API';
      break;
    case 'RULE_CREATE':
      iconName = 'gavel';
      trailTitleString = 'Rule Created';
      break;
    case 'RULE_EDITED':
      iconColor = '#4682B4'; // SteelBlue
      iconName = 'pencil';
      trailTitleString = 'Rule Edited';
      break;
    case 'RULE_DEPLOYED':
      iconColor = 'green';
      iconName = 'check';
      trailTitleString = 'Rule Deployed';
      break;
    case 'RULE_DUPLICATED':
      iconColor = '#4682B4'; // SteelBlue
      iconName = 'copy';
      trailTitleString = 'Rule Duplicated';
      break;
    case 'RULE_DEACTIVATE':
      iconColor = '#FFA500'; // Orange
      iconName = 'ban';
      trailTitleString = 'Rule Deactivated';
      break;
    case 'RULE_ACTION_TRIGGERED':
      iconName = 'external square alternate';
      trailTitleString = 'Action Triggered';
      break;
    case 'ENTITY_ACTION_TRIGGERED':
      iconName = 'external square alternate';
      trailTitleString = 'Action Triggered';
      break;
    case 'ENTITY_API_CREATED':
      iconName = 'upload';
      trailTitleString = 'Entity Created by API';
      iconColor = '#4682B4'; // SteelBlue
      break;
    case 'ENTITY_API_UPDATED':
      iconName = 'upload';
      trailTitleString = 'Entity Updated by API';
      iconColor = '#4682B4'; // SteelBlue
      break;
    case 'CASE_CREATE_SAR':
      iconName = 'file alternate';
      trailTitleString = 'SAR Created from Case';
      iconColor = '#FF6347'; // Tomato
      break;
    case 'SAR_CREATE':
      iconName = 'file alternate';
      trailTitleString = 'SAR Created';
      iconColor = '#FF6347'; // Tomato
      break;
    // REFRESHED
    case 'ATTACHMENT_UPLOADED':
    case 'CASE_MEDIA_UPLOAD':
    case 'ENTITY_MEDIA_UPLOAD':
    case 'ALERT_MEDIA_UPLOAD':
      iconName = 'upload';
      trailTitleString = 'Attachment Uploaded';
      iconColor = '#4682B4'; // SteelBlue
      break;
    case 'CASE_CREATE':
    case 'CASE_API_CREATED':
      iconColor = 'green';
      iconName = 'suitcase';
      trailTitleString = 'Case Created';
      break;
    case 'CASE_EDIT':
    case 'CASE_API_UPDATED':
      iconColor = '#4682B4'; // SteelBlue
      iconName = 'pencil';
      trailTitleString = 'Case Edited';
      break;
    case 'GLOBAL_WHITELIST_ENTITY':
    case 'GLOBAL_WHITELIST_INSTRUMENT':
    case 'GLOBAL_WHITELIST':
      iconName = 'globe';
      trailTitleString = 'Global Rule Whitelisted';
      iconColor = 'green';
      break;
    case 'GLOBAL_UNWHITELIST_ENTITY':
    case 'GLOBAL_UNWHITELIST_INSTRUMENT':
    case 'GLOBAL_UNWHITELIST':
      iconName = 'globe';
      trailTitleString = 'Removed from Global Rule Whitelist';
      iconColor = '#9370DB'; // MediumPurple
      break;
    case 'RULE_WHITELIST_ENTITY':
    case 'RULE_WHITELIST_INSTRUMENT':
    case 'RULE_WHITELIST':
      iconName = 'thumbs up';
      trailTitleString = 'Whitelisted for Rule(s)';
      iconColor = 'green'; // SteelBlue
      break;
    case 'RULE_UNWHITELIST_ENTITY':
    case 'RULE_UNWHITELIST_INSTRUMENT':
    case 'RULE_UNWHITELIST':
      iconName = 'thumbs down';
      trailTitleString = 'Removed from Whitelist for Rule(s)';
      iconColor = '#9370DB'; // MediumPurple
      break;
    default:
      break;
  }
  return { iconColor, iconName, trailTitleString };
};

// convert something like ip_addresses to Ip Addresses
export const formatTitle = (title: string): string => {
  if (title.indexOf('_') === -1) {
    return title;
  }

  return title
    .split('_')
    .map((word) => `${word[0].toUpperCase()}${word.substr(1)}`)
    .join(' ');
};

// used for more complex loading states to indicate with an id what is loading
export const combineRootActionWithId = (
  rootAction: string,
  id: string | number,
) => {
  return rootAction + String(id);
};
type TypeofReturns =
  | 'string'
  | 'number'
  | 'object'
  | 'boolean'
  | 'undefined'
  | 'bigint'
  | 'function'
  | 'symbol';
export function isArrayOfType(arr: any, type: TypeofReturns) {
  return Array.isArray(arr) && arr.every((val) => typeof val === type);
}

export const humanReadableFileSize = (size?: number) => {
  if (!size) {
    return '';
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const formattedFileSize = (size / 1024 ** i).toFixed(1);
  return `${formattedFileSize} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

// Turns the GET params in the URL into an object. Can accept a string like
// ?param1=something&param2=5 or param1=something&param2=5
// Does not do any html decoding
export const searchParamsToObject = (search: string) => {
  const searchParams = search.charAt(0) === '?' ? search.substring(1) : search;
  const paramsKeyValues = searchParams.split('&');
  const toReturn: { [key: string]: string } = {};

  for (let i = 0; i < paramsKeyValues.length; i++) {
    const [key, value] = paramsKeyValues[i].split('=');
    toReturn[key] = `${value}`;
  }

  return toReturn;
};

export const isAuth0Callback = (hash: string) => {
  return /access_token|error/.test(hash);
};

export const isValidationError = (
  errBody: PatronusErrorResponseBody,
): errBody is PatronusValidationErrorBody => {
  return (
    (errBody as PatronusValidationErrorBody | undefined)?.validation_error !==
    undefined
  );
};

// https://docs.unit21.ai/docs/tags
export const TAG_CAP = 1000;
export const getTagLimitReachedError = (tagCount: number) => {
  return `Tag Limit Reached: There is a tag limit per organization of ${TAG_CAP} tags (you have ${tagCount}). Some tags are hidden.`;
};
